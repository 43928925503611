* {
  margin: 0;
  padding: 0;
}


@font-face {
  font-family: 'Cubano Regular';
  src: url('../font/Cubano.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro-Regular-1';
  src: url('../font/AvenirNextLTPro-Regular-1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.marknadsforing {
  overflow-x: hidden;
  overflow-y: visible;
  max-height: 1000vh;
  height: 100vh;
}

.background__m{
  max-width: 100%;
  height: 55vh;
  -o-object-fit: fill;
     object-fit: fill;
}

.marknadsforing {
  background-color: #D3D3D3;
  min-height: 100vh;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

.Loggo__nav {
  width: 250px;
  height: 110px;
  padding: 20px;
  cursor: pointer;
  -o-object-fit: contain;
     object-fit: contain;
  transition: all 300ms ease;
}

.navbar {
  width: 99%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.navbar ul {
  height: 82px;
  width: 65%;
  justify-content: flex-end;
  display: flex;
  gap: 1.5rem;
  list-style-type: none;
  transition: all 300ms ease;
}

.navbar ul li {
  color: white;
  font-family: 'Cubano Regular', sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 300ms ease;
}

.responsive__bar__links {
  display: none;
}

.responsive__navbar__link {
  display: none;
  color: white;
  cursor: pointer;
} 

.toggle__btn {
  color:white;
  width:25px;
  height:25px;
  cursor: pointer;
  display: none;
  transition: all 300ms ease;
}

.close__btn {
  display: none;
  color:white; 
  height:20px;
  width:20px;
  cursor: pointer;
}

.strate__color {
  color: rgb(205, 23, 25);
}

.navbar ul .strate__color_li {
  color: rgb(205, 23, 25);
}

.navbar p:hover {
  cursor: pointer;
}

.bash__text {
  font-family: "AvenirNextLTPro-Regular-1", sans-serif;
}

.title__2 {
  text-align: center;
  color:rgb(205, 23, 25);
  font-size: 50px;
  font-family: "Cubano Regular", sans-serif;
  margin-top: 60px;
  transition: all 300ms ease;
}

.content__container {
  overflow-y: hidden;
}

.wrapper__text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 75px;
}
  
.image-container {
    grid-column: 2 / 3;
    -o-object-fit: cover;
       object-fit: cover;
}
  
.text-container {
    grid-column: 1 / 2;
    font-family: "AvenirNextLTPro-Regular-1", sans-serif;
    margin-right: 100px;
}

div.wrapper__text p {
    margin-bottom: 18px;
    font-size: 20px;
}

.btn__s {
  display: flex;
  margin: auto;
  margin-top: 117px;
  background-color: rgb(205, 23, 25); 
  border: none; 
  color: white; 
  padding: 15px 32px; 
  text-align: center; 
  text-decoration: none;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 10px 8px 16px 0px rgba(0,0,0,0.4);
  font-family: "Cubano Regular", sans-serif;
}
  
::-webkit-scrollbar {
  width: 10px;
  }
  
  ::-webkit-scrollbar-track {
  background-color: #e6e1e1;
  }
  
  ::-webkit-scrollbar-thumb {
  background-color: rgb(84, 86, 90);;
  }
  
  
  @media screen and (max-width:860px){
    .navbar ul {
      display: none;
  }

  .Loggo__nav {
    width: 200px;
    height: 80px;
    padding: 15px;
    cursor: pointer;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .toggle__btn {
    display: block;
    position: relative;
    right: 10px;
    bottom: 20px;
    transition: all 300ms ease;
  }

  .close__btn {
    display: block;
  }

  .navbar div {
    background-color: rgb(84, 86, 90);
    position: fixed;
    top:0;
    left:0;
    height: 100%;
    width:100%;
    gap:1.5rem;
    transition: 1s;
    transform: translateY(-100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    z-index: 1000;

  }

  .responsive__navbar__link {
    display: block;
  }

  .navbar .responsive__navbar {
    transform: none;
    height: 35%;
    width:40%;
    left:57%;
    right: 0;
  }
  
  }

  @media screen and (max-width:560px){
    .Loggo__nav {
      width: 120px;
      height: 70px;
      padding: 10px;
      cursor: pointer;
      -o-object-fit: contain;
         object-fit: contain;
    }

    .background__img{
      height: 30vh;
    }

    .title__2 {
      font-size: 20px;
    }

    .down__text {
      font-size: 20px;
    }

    .text__container h1{
      font-size: 25px;
    }

    .wrapper__text {
      flex-direction: column;
    }

    .text-container {
      margin:8px 20px;
    }

    .toggle__btn {
      bottom: 7px;
    }
  }

  @media screen and (max-width:450px){
    .responsive__navbar__link {
      font-size: 12px;
      font-family: "AvenirNextLTPro-Regular-1", sans-serif;
    } 

   .punkt__rad:before {
      content: " •";
    }

    .background__m {
      background-size: cover;
      height: 30vh;
    }

  }