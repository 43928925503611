* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Cubano Regular';
  src: url('../font/Cubano.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro-Regular-1';
  src: url('../font/AvenirNextLTPro-Regular-1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.home {
  background-color: #D3D3D3;
  min-height: 100vh;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
  overflow-x: hidden;
}

img {
  width: 100%;
  margin-bottom: 10px;
  height: 30vh;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 70%;
     object-position: 50% 70%;
}

.first__tlt {
  text-align: center;
  color:rgb(205, 23, 25);
  font-size: 50px;
  font-family: "Cubano Regular", sans-serif;
}

.bröd__text {
  text-align: center;
  margin: 10px;
  font-size: 22px;
  font-family: "AvenirNextLTPro-Regular-1", sans-serif;
}

.box__container {
  display: flex;
  justify-content: center;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 43px;
  padding: 15px;
  margin-left: -8px;
  box-shadow: rgba(0, 0, 0, 0.35) 6px 2px 16px 0px, rgba(211, 211, 211, 0.8) -6px -2px 16px 0px;
  width: 250px;
  margin-top: 20px;
  background-color: #D3D3D3;
  -webkit-filter: brightness(103%);
          filter: brightness(103%);
  border-radius: 20px 10px;
  margin-bottom: 180px;
}

.img2 {
  width: 180px;
  height: 180px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.box__text {
  margin: 0;
  font-size: 20px;
  font-family: 'Cubano Regular', sans-serif;
  color: rgb(84, 86, 90);
}

.texto__t {
  text-align: center;
  font-size: 14px;
  font-family: "AvenirNextLTPro-Regular-1", sans-serif;
}

.under__t {
  text-align: center;
  font-size: 13px;
  font-family: 'Cubano Regular', sans-serif;
  color: rgb(84, 86, 90);
  margin-top: 7px;
}

.image__container {
  position: relative;
}

.home__container {
  margin-top: 20px;
}

.Loggo__nav {
  width: 250px;
  height: 110px;
  padding: 20px;
  display: flex;
  cursor: pointer;
  -o-object-fit: contain;
     object-fit: contain;
  transition: all 300ms ease;
  position: relative;
  right: 85px;
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
}

.navbar ul {
  height: 82px;
  width: 65%;
  justify-content: flex-end;
  display: flex;
  gap: 1.5rem;
  list-style-type: none;
  transition: all 300ms ease;
}

.navbar ul li {
  color: white;
  font-family: 'Cubano Regular', sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 300ms ease;
}

.strate__color {
  color: rgb(205, 23, 25);
}

.responsive__bar__links {
  display: none;
}

.toggle__btn {
  color:white;
  width:25px;
  height:25px;
  cursor: pointer;
  display: none;
  transition: all 300ms ease;
}

.close__btn {
  display: none;
  color:white; 
  height:20px;
  width:20px;
  cursor: pointer;
}

.navbar p:hover {
  cursor: pointer;
}

.box__container p:hover {
  cursor: pointer;
}

.responsive__navbar__link {
  display: none;
  color: white;
  cursor: pointer;
  transition: all 300ms ease;
} 

@media screen and (max-width:860px){
  .home {
    padding-bottom: 150px;
  }
  .navbar ul {
    display: none;
  }

  .Loggo__nav {
    width: 200px;
    height: 80px;
    padding: 15px;
    cursor: pointer;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .toggle__btn {
    display: block;
    position: relative;
    right: 10px;
    bottom: 20px;
    transition: all 300ms ease;
  }

  .close__btn {
    display: block;
  }

  .navbar div {
    background-color: rgb(84, 86, 90);
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width:100%;
    gap:1.5rem;
    transition: 1s;
    transform: translateY(-150vh);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    z-index: 1000;

  }

  .responsive__navbar__link {
    display: block;
  }

  .navbar .responsive__navbar {
    transform: none;
    height: 35%;
    width:40%;
    left:57%;
    right: 0;
  }
  
}

@media screen and (max-width:810px){
  .Loggo__nav {
    width: 120px;
    height: 70px;
    padding: 10px;
    cursor: pointer;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .background__img{
    height: 30vh;
  }

  .first__tlt {
    font-size: 30px;
  }

  .bröd__text {
    font-size: 16px;
  }

  .box__container {
    flex-direction: column;
    margin-left: 80px;
  }

  .box {
    margin-bottom: 12px;
  }

  .toggle__btn {
    bottom: 7px;
  }

}


@media screen and (max-width:1060px){
  .box {
    padding: 10px;
    width: 200px;
  }

  .img2 {
    width: 150px;
    height: 150px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 50%;
  }
}

@media screen and (max-width:450px){
  .responsive__navbar__link {
    font-size: 12px;
  } 

}