* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Cubano Regular';
  src: url('../font/Cubano.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro-Regular-1';
  src: url('../font/AvenirNextLTPro-Regular-1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.background__img{
  width: 100%;
  margin-bottom: 10px;
  height: 40vh;
  -o-object-position: 50% 37%;
     object-position: 50% 37%;
}

.strategi {
  background-color: #D3D3D3;
  min-height: 140vh;
  height: 140vh;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
  overflow-x: hidden;
}

.Loggo__nav {
  width: 250px;
  height: 110px;
  padding: 20px;
  cursor: pointer;
  -o-object-fit: contain;
     object-fit: contain;
  transition: all 300ms ease;
}

.navbar {
  width: 99%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.navbar ul {
  height: 82px;
  width: 65%;
  justify-content: flex-end;
  display: flex;
  gap: 1.5rem;
  list-style-type: none;
  transition: all 300ms ease;
}

.navbar ul li {
  color: white;
  font-family: 'Cubano Regular', sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 300ms ease;
}

.responsive__bar__links {
  display: none;
}

.toggle__btn {
  color:white;
  width:25px;
  height:25px;
  cursor: pointer;
  display: none;
  transition: all 300ms ease;
}

.close__btn {
  display: none;
  color:white; 
  height:20px;
  width:20px;
  cursor: pointer;
}

.strate__color {
  color: rgb(205, 23, 25);
}

.navbar ul .strate__color_li {
  color: rgb(205, 23, 25);
}

.navbar p:hover {
  cursor: pointer;
}

h1 {
  text-align: center;
  color:rgb(205, 23, 25);
  font-size: 51px;
  font-family: "Cubano Regular", sans-serif;
  margin-top: 30px;
}

.down__text {
  text-align: center;
  margin: 10px;
  font-size: 22px;
  font-family: "AvenirNextLTPro-Regular-1", sans-serif;
  margin-top: 30px;
  transition: all 300ms ease;
}

.btn__s {
  display: flex;
  margin: auto;
  margin-top: 117px;
  background-color: rgb(205, 23, 25); 
  border: none; 
  color: white; 
  padding: 15px 32px; 
  text-align: center; 
  text-decoration: none;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 10px 8px 16px 0px rgba(0,0,0,0.4);
  font-family: "Cubano Regular", sans-serif;
}

.responsive__navbar__link {
  display: none;
  color: white;
  cursor: pointer;
  transition: all 300ms ease;
} 

/* ============================ QUERIES ================================== */


@media screen and (max-width:860px){
  .navbar ul {
    display: none;
  }

  .Loggo__nav {
    width: 200px;
    height: 80px;
    padding: 15px;
    cursor: pointer;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .toggle__btn {
    display: block;
    position: relative;
    right: 10px;
    bottom: 20px;
  }

  .close__btn {
    display: block;
  }

  .navbar div {
    background-color: rgb(84, 86, 90);
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width:100%;
    gap:1.5rem;
    transition: 1s;
    transform: translateY(-150vh);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    z-index: 1000;

  }

  .responsive__navbar__link {
    display: block;
  }

  .navbar .responsive__navbar {
    transform: none;
    height: 35%;
    width:40%;
    left:57%;
    right: 0;
  }
}

@media screen and (max-width:560px){
  .strategi {
    min-height: 120vh;
    height: 120vh;
  }

  .Loggo__nav {
    width: 120px;
    height: 70px;
    padding: 10px;
    cursor: pointer;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .background__img{
    height: 30vh;
  }

  .toggle__btn {
    bottom: 7px;
  }

  

}

@media screen and (max-width:960px){
  .down__text {
    font-size: 50px;
    font-weight: 200;
  }
}

@media screen and (max-width:450px){
  .responsive__navbar__link {
    font-size: 12px;
  } 
}