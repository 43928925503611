
footer {
  background-color: rgb(84, 86, 90);
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 0px;
  order: 1;
  z-index: 1000;

}

.title__footer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.footer__img {
  color: rgb(205, 23, 25);
  font-size: 16px;
  margin-top: 17px;
  margin-left: 35px;
}

.text__info {
color: white;
text-align: top left;
order: 2;
font-family: "AvenirNextLTPro-Regular-1", sans-serif;
}
.text__position{
  margin-top: 55px;
  margin-left: -67px;
}

.Loggo__img{
    width: 200px;
    height: 80px;
    cursor: pointer;
    -o-object-fit: contain;
       object-fit: contain;
    margin-right: 20px;
    transition: all 300ms ease;
    margin-bottom: -20px;
}

.container {
  max-width: 960px;
  margin: 0 auto;
}

@media screen and (max-width:660px){
  .Loggo__img{
    width: 130px;
    height: 80px;
    cursor: pointer;
    -o-object-fit: contain;
       object-fit: contain;
    margin-right: 20px;
    margin-bottom: -47px;
}
.text__info {
  font-family: "AvenirNextLTPro-Regular-1", sans-serif;
}
}