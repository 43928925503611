* {
  margin: 0;
  padding: 0;
}

.utveckling {
  background-color: #d3d3d3;
  min-height: 120vh;
  height: 120vh;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
  padding-bottom: 120px;
  overflow-x: hidden;
}

.background__image{
  width: 100%;
  margin-bottom: 10px;
  height: 40vh;
  -o-object-position: 50% 55%;
     object-position: 50% 55%;
}

.Loggo__nav {
  width: 250px;
  height: 110px;
  padding: 20px;
  cursor: pointer;
  -o-object-fit: contain;
     object-fit: contain;
  margin-left: 90px;
}

.navbar {
  width: 99%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.navbar ul {
  width: 65%;
  height: 82px;
  justify-content: flex-end;
  display: flex;
  gap: 1.5rem;
  list-style-type: none;
  transition: all 300ms ease;
}

.navbar ul li {
  color: white;
  font-family: 'Cubano Regular', sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 300ms ease;
}

.responsive__bar__links {
  display: none;
}

.toggle__btn {
  display: block;
  position: relative;
  right: 10px;
  bottom: 20px;
  transition: all 300ms ease;
}

.close__btn {
  display: none;
  color:white; 
  height:20px;
  width:20px;
  cursor: pointer;
}

.strate__color {
  color: rgb(205, 23, 25);
}

.navbar ul .strate__color_li {
  color: rgb(205, 23, 25);
}

.navbar p:hover {
  cursor: pointer;
}

h1 {
  text-align: center;
  color:rgb(205, 23, 25);
  font-size: 50px;
  font-family: "Cubano Regular", sans-serif;
  margin-top: 30px;
}

.r__text {
  text-align: center;
  margin: 10px;
  font-size: 22px;
  font-family: "AvenirNextLTPro-Regular-1", sans-serif;
  margin-top: 30px;
}

.btn__u {
  display: flex;
  margin: auto;
  margin-top: 117px;
  background-color: rgb(205, 23, 25); 
  border: none; 
  color: white; 
  padding: 15px 32px; 
  text-align: center; 
  text-decoration: none;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 10px 8px 16px 0px rgba(0,0,0,0.4);
  font-family: "Cubano Regular", sans-serif;
}

.responsive__navbar__link {
  display: none;
  color: white;
  cursor: pointer;
  transition: all 300ms ease;
} 

@media screen and (max-width:860px){
  .navbar ul {
    display: none;
}

.Loggo__nav {
  width: 200px;
  height: 80px;
  padding: 15px;
  cursor: pointer;
  -o-object-fit: contain;
     object-fit: contain;
}

.toggle__btn {
  display: block;
}

.close__btn {
  display: block;
}

.navbar div {
  background-color: rgb(84, 86, 90);
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width:100%;
  gap:1.5rem;
  transition: 1s;
  transform: translateY(-150vh);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  z-index: 1000;

}

.responsive__navbar__link {
  display: block;
}

.navbar .responsive__navbar {
  transform: none;
  height: 35%;
  width:40%;
  left:57%;
  right: 0;
}

}


@media screen and (max-width:560px){
  .utveckling {
    min-height: 115vh;
    height: 115vh;
  }

  .Loggo__nav {
    width: 120px;
    height: 70px;
    padding: 10px;
    cursor: pointer;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .background__img{
    height: 30vh;
  }

  .toggle__btn {
    bottom: 7px;
  }

  .tx__container h1 {
    font-size: 28px;
  }
}

@media screen and (max-width:450px){
  .responsive__navbar__link {
    font-size: 12px;
  } 
}